/*
 * Copyright (C) 2020-present Borza LLC. All rights reserved.
 */

import { faStar } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { motion } from "framer-motion";
import Head from "next/head";
import Link from "next/link";
import { Component } from "react";
import LazyLoad from "react-lazy-load";

import Banner from "../components/banner";
import Earth from "../components/earth";
import Hero from "../components/hero";
import Nature from "../components/nature";
import Newsletter from "../components/newsletter";
import OpenGraphHead from "../components/og-head";
import Purpose from "../components/purpose";
import TwitterHead from "../components/twitter-head";
import {
  toAbsolutePageURL,
  toRelativePageURL,
} from "../utils/url";

interface IProps {
  query: {
    market: string;
  };
}

class Index extends Component<IProps> {
  public static getInitialProps({ query }: IProps) {
    query.market = query.market ?? "en-US";

    return { query };
  }

  public render() {
    const {
      query: {
        market,
      },
    } = this.props;

    const canonicalUrl = toAbsolutePageURL("/", market);
    const alt = "Lasting Smiles";
    const title = "Lasting Smiles Organic Lip Balms and Lip Care";
    const description = "Lasting Smiles is the best all natural, organic, fair trade, sustainable, cruelty free, dermatologist recommended lip balm, chapstick, lip care, and lip treatments for healing, hydrating, and moisturizing your dry, chapped lips. " +
      "Each conditioning and soothing lip care and lip balm product ingredients recipe is made to heal, protect, soften, and smooth your beautiful and healthy lips with daily use. " +
      "A mission brand with a do good cause of helping people in need.";

    const list = {
      hidden: {},
      visible: {
        transition: {
          delay: 0.25,
          staggerChildren: 0.1,
          when: "beforeChildren",
        },
      },
    };

    const item = {
      hidden: {
        opacity: 0,
        scale: 0,
        y: 50,
      },
      visible: {
        opacity: 1,
        scale: 1,
        y: 0,
      },
    };

    return (
      <>
        <Head>
          <title>{title}</title>
          <meta
            content={description}
            name="description"
          />
          <link
            href={canonicalUrl}
            rel="canonical"
          />
          <link
            href={toAbsolutePageURL("/", "en-US")}
            hrefLang="x-default"
            rel="alternate"
          />
          <link
            href={toAbsolutePageURL("/", "en-US")}
            hrefLang="en-US"
            rel="alternate"
          />
          <link
            href={toAbsolutePageURL("/", "en-CA")}
            hrefLang="en-CA"
            rel="alternate"
          />
          <script
            dangerouslySetInnerHTML={{
              __html: JSON.stringify({
                "@context": "https://schema.org",
                "@type": "Organization",
                "address": {
                  "@type": "PostalAddress",
                  "addressCountry": "USA",
                  "addressLocality": "Seattle",
                  "addressRegion": "WA",
                  "postalCode": "98115",
                  "postOfficeBoxNumber": "15944",
                },
                "email": "info@lastingsmiles.org",
                "logo": "/chromiums/schema-512x512-icon.png",
                "name": "Lasting Smiles",
                "sameAs": [
                  "https://www.instagram.com/sharelastingsmiles/",
                ],
                "url": "https://www.lastingsmiles.org/",
              }),
            }}
            type="application/ld+json"
          />
          <script
            dangerouslySetInnerHTML={{
              __html: JSON.stringify({
                "@context": "https://schema.org",
                "@type": "BreadcrumbList",
                "itemListElement": [
                  {
                    "@type": "ListItem",
                    "item": toAbsolutePageURL("/", market),
                    "name": "Lasting Smiles",
                    "position": 1,
                  },
                ],
              }),
            }}
            type="application/ld+json"
          />
        </Head>
        <OpenGraphHead
          alt={alt}
          description={description}
          slug="index"
          title={title}
          type="website"
          url={canonicalUrl}
        />
        <TwitterHead
          alt={alt}
          description={description}
          slug="index"
          title={title}
        />
        <Banner />
        <Newsletter />
        <Hero />
        <Nature />
        <Earth />
        <section className="px-2 py-10 sm:px-5">
          <h2 className="font-medium font-serif text-2xl text-center">
            Lasting&nbsp;Smiles Organic&nbsp;Lip&nbsp;Balms
          </h2>
          <p className="text-center text-xl">
            So pure you can eat them.
          </p>
          <motion.div
            animate="visible"
            className="flex flex-row flex-wrap items-center justify-around"
            initial="hidden"
            variants={list}
          >
            <motion.div
              className="flex-initial m-5 text-center"
              variants={item}
            >
              <Link
                as={toRelativePageURL("/products/peach-blossom-lip-balm", market)}
                href={{
                  pathname: "/products/peach-blossom-lip-balm",
                  query: { market },
                }}
              >
                <a>
                  <picture>
                    <source
                      srcSet={require("../public/images/products/peach-blossom-lip-balm/single.webp") as string}
                      type="image/webp"
                    />
                    <source
                      srcSet={require("../public/images/products/peach-blossom-lip-balm/single.jpg") as string}
                      type="image/jpeg"
                    />
                    <motion.img
                      alt="Lasting Smiles Peach Blossom Lip Balm"
                      className="rounded"
                      src={require("../public/images/products/peach-blossom-lip-balm/single.jpg") as string}
                      style={{ maxWidth: 200 }}
                      whileHover={{ scale: 1.1 }}
                      whileTap={{ scale: 0.9 }}
                    />
                  </picture>
                  <br />
                  <span className="border-b-2 border-black font-medium inline-block uppercase">
                    Peach&nbsp;Blossom
                  </span>
                </a>
              </Link>
            </motion.div>
            <motion.div
              className="flex-initial m-5 text-center"
              variants={item}
            >
              <Link
                as={toRelativePageURL("/products/peppermint-creme-lip-balm", market)}
                href={{
                  pathname: "/products/peppermint-creme-lip-balm",
                  query: { market },
                }}
              >
                <a>
                  <picture>
                    <source
                      srcSet={require("../public/images/products/peppermint-creme-lip-balm/single.webp") as string}
                      type="image/webp"
                    />
                    <source
                      srcSet={require("../public/images/products/peppermint-creme-lip-balm/single.jpg") as string}
                      type="image/jpeg"
                    />
                    <motion.img
                      alt="Lasting Smiles Peppermint Creme Lip Balm"
                      className="rounded"
                      src={require("../public/images/products/peppermint-creme-lip-balm/single.jpg") as string}
                      style={{ maxWidth: 200 }}
                      whileHover={{ scale: 1.1 }}
                      whileTap={{ scale: 0.9 }}
                    />
                  </picture>
                  <br />
                  <span className="border-b-2 border-black font-medium inline-block uppercase">
                    Peppermint&nbsp;Creme
                  </span>
                </a>
              </Link>
            </motion.div>
            <motion.div
              className="flex-initial m-5 text-center"
              variants={item}
            >
              <Link
                as={toRelativePageURL("/products/passionfruit-mango-lip-balm", market)}
                href={{
                  pathname: "/products/passionfruit-mango-lip-balm",
                  query: { market },
                }}
              >
                <a>
                  <picture>
                    <source
                      srcSet={require("../public/images/products/passionfruit-mango-lip-balm/single.webp") as string}
                      type="image/webp"
                    />
                    <source
                      srcSet={require("../public/images/products/passionfruit-mango-lip-balm/single.jpg") as string}
                      type="image/jpeg"
                    />
                    <motion.img
                      alt="Lasting Smiles Passionfruit Mango Lip Balm"
                      className="rounded"
                      src={require("../public/images/products/passionfruit-mango-lip-balm/single.jpg") as string}
                      style={{ maxWidth: 200 }}
                      whileHover={{ scale: 1.1 }}
                      whileTap={{ scale: 0.9 }}
                    />
                  </picture>
                  <br />
                  <span className="border-b-2 border-black font-medium inline-block uppercase">
                    Passionfruit&nbsp;Mango
                  </span>
                </a>
              </Link>
            </motion.div>
            <motion.div
              className="flex-initial m-5 text-center"
              variants={item}
            >
              <Link
                as={toRelativePageURL("/products/strawberry-creme-organic-lip-balm", market)}
                href={{
                  pathname: "/products/strawberry-creme-organic-lip-balm",
                  query: { market },
                }}
              >
                <a>
                  <picture>
                    <source
                      srcSet={require("../public/images/products/strawberry-creme-organic-lip-balm/single.webp") as string}
                      type="image/webp"
                    />
                    <source
                      srcSet={require("../public/images/products/strawberry-creme-organic-lip-balm/single.jpg") as string}
                      type="image/jpeg"
                    />
                    <motion.img
                      alt="Lasting Smiles Strawberry Creme Organic Lip Balm"
                      className="rounded"
                      src={require("../public/images/products/strawberry-creme-organic-lip-balm/single.jpg") as string}
                      style={{ maxWidth: 200 }}
                      whileHover={{ scale: 1.1 }}
                      whileTap={{ scale: 0.9 }}
                    />
                  </picture>
                  <br />
                  <span className="border-b-2 border-black font-medium inline-block uppercase">
                    Strawberry&nbsp;Creme
                  </span>
                </a>
              </Link>
            </motion.div>
            <motion.div
              className="flex-initial m-5 text-center"
              variants={item}
            >
              <Link
                as={toRelativePageURL("/products/vanilla-bean-organic-lip-balm", market)}
                href={{
                  pathname: "/products/vanilla-bean-organic-lip-balm",
                  query: { market },
                }}
              >
                <a>
                  <picture>
                    <source
                      srcSet={require("../public/images/products/vanilla-bean-organic-lip-balm/single.webp") as string}
                      type="image/webp"
                    />
                    <source
                      srcSet={require("../public/images/products/vanilla-bean-organic-lip-balm/single.jpg") as string}
                      type="image/jpeg"
                    />
                    <motion.img
                      alt="Lasting Smiles Vanilla Bean Organic Lip Balm"
                      className="rounded"
                      src={require("../public/images/products/vanilla-bean-organic-lip-balm/single.jpg") as string}
                      style={{ maxWidth: 200 }}
                      whileHover={{ scale: 1.1 }}
                      whileTap={{ scale: 0.9 }}
                    />
                  </picture>
                  <br />
                  <span className="border-b-2 border-black font-medium inline-block uppercase">
                    Vanilla&nbsp;Bean
                  </span>
                </a>
              </Link>
            </motion.div>
            <motion.div
              className="flex-initial m-5 text-center"
              variants={item}
            >
              <Link
                as={toRelativePageURL("/products/very-cherry-lip-balm", market)}
                href={{
                  pathname: "/products/very-cherry-lip-balm",
                  query: { market },
                }}
              >
                <a>
                  <picture>
                    <source
                      srcSet={require("../public/images/products/very-cherry-lip-balm/single.webp") as string}
                      type="image/webp"
                    />
                    <source
                      srcSet={require("../public/images/products/very-cherry-lip-balm/single.jpg") as string}
                      type="image/jpeg"
                    />
                    <motion.img
                      alt="Lasting Smiles Very Cherry Lip Balm"
                      className="rounded"
                      src={require("../public/images/products/very-cherry-lip-balm/single.jpg") as string}
                      style={{ maxWidth: 200 }}
                      whileHover={{ scale: 1.1 }}
                      whileTap={{ scale: 0.9 }}
                    />
                  </picture>
                  <br />
                  <span className="border-b-2 border-black font-medium inline-block uppercase">
                    Very&nbsp;Cherry
                  </span>
                </a>
              </Link>
            </motion.div>
            <motion.div
              className="flex-initial m-5 text-center"
              variants={item}
            >
              <Link
                as={toRelativePageURL("/products/variety-lip-balm", market)}
                href={{
                  pathname: "/products/variety-lip-balm",
                  query: { market },
                }}
              >
                <a>
                  <picture>
                    <source
                      srcSet={require("../public/images/products/variety-lip-balm/pack.webp") as string}
                      type="image/webp"
                    />
                    <source
                      srcSet={require("../public/images/products/variety-lip-balm/pack.jpg") as string}
                      type="image/jpeg"
                    />
                    <motion.img
                      alt="Lasting Smiles Variety Lip Balm"
                      className="rounded"
                      src={require("../public/images/products/variety-lip-balm/pack.jpg") as string}
                      style={{ maxWidth: 200 }}
                      whileHover={{ scale: 1.1 }}
                      whileTap={{ scale: 0.9 }}
                    />
                  </picture>
                  <br />
                  <span className="border-b-2 border-black font-medium inline-block uppercase">
                    Variety&nbsp;Collection
                  </span>
                </a>
              </Link>
            </motion.div>
          </motion.div>
        </section>
        <section className="bg-gray-100 py-10">
          <h2 className="font-medium font-serif px-2 sm:px-5 text-2xl text-center">
            <span
              className="block"
              style={{ color: "gold" }}
            >
              <FontAwesomeIcon
                fixedWidth={true}
                icon={faStar}
                size="lg"
              />
              <FontAwesomeIcon
                fixedWidth={true}
                icon={faStar}
                size="lg"
              />
              <FontAwesomeIcon
                fixedWidth={true}
                icon={faStar}
                size="lg"
              />
              <FontAwesomeIcon
                fixedWidth={true}
                icon={faStar}
                size="lg"
              />
              <FontAwesomeIcon
                fixedWidth={true}
                icon={faStar}
                size="lg"
              />
            </span>
            <span className="block my-5 uppercase">
              Over
              <span>{" "}</span>
              <strong
                className="px-1"
                style={{ background: "linear-gradient(180deg, rgba(255, 255, 255, 0) 55%, gold 55%)" }}
              >
                100,000
              </strong>
              <span>{" "}</span>
              Verified 5&#8209;Star&nbsp;Reviews
            </span>
          </h2>
          <div className="overflow-x-auto overflow-y-hidden scrolling-touch sm:px-5">
            <motion.div
              animate="visible"
              className="flex-row inline-flex sm:flex sm:flex-wrap sm:items-stretch sm:justify-around text-white"
              initial="hidden"
              variants={list}
            >
              <motion.div
                className="box-border flex flex-col flex-initial font-medium font-serif min-w-200 mx-2 my-5 p-2 sm:min-w-auto sm:mx-5 rounded text-center"
                style={{
                  backgroundImage: `url("${require("../public/images/testimonials/peach-blossom-lip-balm/background-1.jpg")}")` as string,
                  backgroundSize: "cover",
                  maxWidth: 200,
                }}
                variants={item}
              >
                <div className="my-5 whitespace-no-wrap">
                  <FontAwesomeIcon
                    fixedWidth={true}
                    icon={faStar}
                  />
                  <FontAwesomeIcon
                    fixedWidth={true}
                    icon={faStar}
                  />
                  <FontAwesomeIcon
                    fixedWidth={true}
                    icon={faStar}
                  />
                  <FontAwesomeIcon
                    fixedWidth={true}
                    icon={faStar}
                  />
                  <FontAwesomeIcon
                    fixedWidth={true}
                    icon={faStar}
                  />
                </div>
                <blockquote className="flex flex-1 flex-col">
                  <p className="flex-1">
                    &ldquo;Keeps my lips feeling plump and healthy even on the driest of days!&rdquo;
                  </p>
                  <footer className="flex-initial my-5">&ndash; Hannah</footer>
                </blockquote>
              </motion.div>
              <motion.div
                className="box-border flex flex-col flex-initial font-medium font-serif min-w-200 mx-2 my-5 p-2 sm:min-w-auto sm:mx-5 rounded text-center"
                style={{
                  backgroundImage: `url("${require("../public/images/testimonials/peppermint-creme-lip-balm/background-1.jpg")}")` as string,
                  backgroundSize: "cover",
                  maxWidth: 200,
                }}
                variants={item}
              >
                <div className="my-5 whitespace-no-wrap">
                  <FontAwesomeIcon
                    fixedWidth={true}
                    icon={faStar}
                  />
                  <FontAwesomeIcon
                    fixedWidth={true}
                    icon={faStar}
                  />
                  <FontAwesomeIcon
                    fixedWidth={true}
                    icon={faStar}
                  />
                  <FontAwesomeIcon
                    fixedWidth={true}
                    icon={faStar}
                  />
                  <FontAwesomeIcon
                    fixedWidth={true}
                    icon={faStar}
                  />
                </div>
                <blockquote className="flex flex-1 flex-col">
                  <p className="flex-1">
                    &ldquo;This is a perfect lip balm.
                    It's the lip balm that frustrates me the most when I misplace it.&rdquo;
                  </p>
                  <footer className="flex-initial my-5">&ndash; Kristin</footer>
                </blockquote>
              </motion.div>
              <motion.div
                className="box-border flex flex-col flex-initial font-medium font-serif min-w-200 mx-2 my-5 p-2 sm:min-w-auto sm:mx-5 rounded text-center"
                style={{
                  backgroundImage: `url("${require("../public/images/testimonials/passionfruit-mango-lip-balm/background-1.jpg")}")` as string,
                  backgroundSize: "cover",
                  maxWidth: 200,
                }}
                variants={item}
              >
                <div className="flex-initial my-5 whitespace-no-wrap">
                  <FontAwesomeIcon
                    fixedWidth={true}
                    icon={faStar}
                  />
                  <FontAwesomeIcon
                    fixedWidth={true}
                    icon={faStar}
                  />
                  <FontAwesomeIcon
                    fixedWidth={true}
                    icon={faStar}
                  />
                  <FontAwesomeIcon
                    fixedWidth={true}
                    icon={faStar}
                  />
                  <FontAwesomeIcon
                    fixedWidth={true}
                    icon={faStar}
                  />
                </div>
                <blockquote className="flex flex-1 flex-col">
                  <p className="flex-1">
                    &ldquo;I love everything about this product &ndash; its ingredients, smell, occasional taste (guilty of licking lips sometimes), and good impact.
                    Perfect pick!&rdquo;
                  </p>
                  <footer className="flex-initial my-5">&ndash; Amal</footer>
                </blockquote>
              </motion.div>
              <motion.div
                className="box-border flex flex-col flex-initial font-medium font-serif min-w-200 mx-2 my-5 p-2 sm:min-w-auto sm:mx-5 rounded text-center"
                style={{
                  backgroundImage: `url("${require("../public/images/testimonials/very-cherry-lip-balm/background-1.jpg")}")` as string,
                  backgroundSize: "cover",
                  maxWidth: 200,
                }}
                variants={item}
              >
                <div className="flex-initial my-5 whitespace-no-wrap">
                  <FontAwesomeIcon
                    fixedWidth={true}
                    icon={faStar}
                  />
                  <FontAwesomeIcon
                    fixedWidth={true}
                    icon={faStar}
                  />
                  <FontAwesomeIcon
                    fixedWidth={true}
                    icon={faStar}
                  />
                  <FontAwesomeIcon
                    fixedWidth={true}
                    icon={faStar}
                  />
                  <FontAwesomeIcon
                    fixedWidth={true}
                    icon={faStar}
                  />
                </div>
                <blockquote className="flex flex-1 flex-col">
                  <p className="flex-1">
                    &ldquo;I love it!
                    Light and balmy!
                    Not waxy at all!&rdquo;
                  </p>
                  <footer className="flex-initial my-5">&ndash; Lynn</footer>
                </blockquote>
              </motion.div>
              <motion.div
                className="box-border flex flex-col flex-initial font-medium font-serif min-w-200 mx-2 my-5 p-2 sm:min-w-auto sm:mx-5 rounded text-center"
                style={{
                  backgroundImage: `url("${require("../public/images/testimonials/variety-lip-balm/background-1.jpg")}")` as string,
                  backgroundSize: "cover",
                  maxWidth: 200,
                }}
                variants={item}
              >
                <div className="flex-initial my-5 whitespace-no-wrap">
                  <FontAwesomeIcon
                    fixedWidth={true}
                    icon={faStar}
                  />
                  <FontAwesomeIcon
                    fixedWidth={true}
                    icon={faStar}
                  />
                  <FontAwesomeIcon
                    fixedWidth={true}
                    icon={faStar}
                  />
                  <FontAwesomeIcon
                    fixedWidth={true}
                    icon={faStar}
                  />
                  <FontAwesomeIcon
                    fixedWidth={true}
                    icon={faStar}
                  />
                </div>
                <blockquote className="flex flex-1 flex-col">
                  <p className="flex-1">
                    &ldquo;I loved everything about this lip balm.
                    It smells great, makes my lips feel awesome, and it gives back.&rdquo;
                  </p>
                  <footer className="flex-initial my-5">&ndash; Courtney</footer>
                </blockquote>
              </motion.div>
            </motion.div>
          </div>
          <div className="px-2 sm:px-5 text-center">
            <Link
              as={toRelativePageURL("/products", market as string)}
              href={{
                pathname: "/products",
                query: { market },
              }}
            >
              <a className="bg-black border border-black duration-500 ease-in-out font-medium font-serif hover:bg-default hover:text-black inline-block my-5 px-5 py-3 rounded shadow uppercase text-lg text-white transition">
                Pick Your Lip Balm
              </a>
            </Link>
          </div>
        </section>
        <section className="py-10">
          <div className="max-w-screen-lg mx-auto">
            <div className="flex flex-col items-center sm:flex-row">
              <div className="flex-1 px-2 sm:px-5">
                <h2 className="font-medium font-serif text-2xl">
                  Lip Care that Cares
                  <br />
                  for People and our&nbsp;Planet:
                </h2>
                <ol className="xl:list-decimal text-xl">
                  <li>100% organic and fair trade ingredients;</li>
                  <li>Tubes are made from 100% repurposed bottle caps;</li>
                  <li>Packaging is 100% compostable &amp; environmentally friendly;</li>
                  <li>Choose the cause YOU care about and WE donate;</li>
                  <li>Smile for a Cause;</li>
                  <li>Use up and purchase more.</li>
                </ol>
              </div>
              <div className="flex-1 px-2 sm:px-5">
                <LazyLoad
                  className="mx-auto my-5 overflow-hidden rounded"
                  offset={250}
                  style={{
                    maxHeight: 640,
                    maxWidth: 640,
                  }}
                >
                  <video
                    autoPlay={true}
                    className="mx-auto"
                    controls={true}
                    height="640"
                    loop={true}
                    muted={true}
                    width="640"
                  >
                    <source
                      src={require("../public/videos/circle-of-care.mp4")}
                      type="video/mp4"
                    />
                    Your browser does not support the video tag.
                  </video>
                </LazyLoad>
              </div>
            </div>
          </div>
        </section>
        <Purpose />
      </>
    );
  }
}

export default Index;
