/*
 * Copyright (C) 2020-present Borza LLC. All rights reserved.
 */

import { motion } from "framer-motion";
import Link from "next/link";
import { useRouter } from "next/router";
import {
  useEffect,
  useState,
} from "react";

import { toRelativePageURL } from "../utils/url";

const Hero = () => {
  const {
    query: {
      market,
    },
  } = useRouter();

  const causes = [
    "a Cause",
    "Health",
    "Equality",
    "Hope",
  ];

  const [[causeId], setState] = useState([0]);

  useEffect(() => {
    const interval = setInterval(() => {
      setState([(causeId + 1) % causes.length]);
    }, 2e3);

    return () => {
      clearInterval(interval);
    };
  }, [causeId]);

  return (
    <section>
      <div className="flex flex-col-reverse items-stretch sm:flex-row">
        <div
          className="flex flex-1 items-center justify-center sm:justify-end"
          style={{
            backgroundImage: `url("${require("../public/images/backgrounds/hero-1.jpg")}")` as string,
            backgroundPositionY: "center",
            backgroundSize: "cover",
          }}
        >
          <picture>
            <source
              srcSet={require("../public/images/photos/blavka-2.webp") as string}
              type="image/webp"
            />
            <source
              srcSet={require("../public/images/photos/blavka-2.jpg") as string}
              type="image/jpeg"
            />
            <img
              alt="Lasting Smiles People"
              className="-mt-16 border-8 border-purpose flex-initial max-h-300 max-w-300 mb-64 relative sm:-mr-16 sm:mb-0 sm:max-h-400 sm:max-w-400 rounded-full"
              src={require("../public/images/photos/blavka-2.jpg") as string}
            />
          </picture>
        </div>
        <div
          className="flex-1 pb-32 pt-12 px-2 sm:pl-16 sm:pr-5 sm:py-56"
          style={{
            backgroundImage: `url("${require("../public/images/backgrounds/hero-2.jpg")}")` as string,
            backgroundSize: "cover",
          }}
        >
          <h1 className="font-semibold font-serif sm:text-5xl text-3xl text-center text-purpose">
            The World's Most Caring Lip&nbsp;Care
          </h1>
          <p className="font-semibold font-serif text-2xl text-center text-purpose">
            <motion.span
              animate={{ opacity: 1}}
              exit={{ opacity: 0 }}
              initial={{ opacity: 0 }}
              key={causeId}
            >
              Smile for
              <span>{" "}</span>
              <span className="text-black">{causes[causeId]}</span>
            </motion.span>
          </p>
          <div className="text-center">
            <Link
              as={toRelativePageURL("/products", market as string)}
              href={{
                pathname: "/products",
                query: { market },
              }}
            >
              <a className="bg-black border border-black duration-500 ease-in-out font-medium font-serif hover:bg-default hover:text-black inline-block my-5 px-5 py-3 rounded shadow uppercase text-lg text-white transition">
                Shop for Good
              </a>
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Hero;
