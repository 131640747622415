/*
 * Copyright (C) 2020-present Borza LLC. All rights reserved.
 */

import Link from "next/link";
import { useRouter } from "next/router";

import { toRelativePageURL } from "../utils/url";

const Earth = () => {
  const {
    query: {
      market,
    },
  } = useRouter();

  return (
    <section
      className="bg-green-100 px-2 py-10 sm:px-5"
      style={{
        backgroundImage: `url("${require("../public/images/backgrounds/earth.jpg")}")` as string,
        backgroundSize: "cover",
      }}
    >
      <div className="max-w-screen-lg mx-auto">
        <h2 className="font-medium font-serif text-2xl text-center">
          From a factory called Earth.
        </h2>
        <div className="flex flex-row flex-wrap items-center justify-around">
          <div className="flex-initial m-5">
            <picture>
              <source
                srcSet={require("../public/images/index/sourcing.webp") as string}
                type="image/webp"
              />
              <source
                srcSet={require("../public/images/index/sourcing.jpg") as string}
                type="image/jpeg"
              />
              <img
                alt="Lasting Smiles Sourcing"
                className="max-h-300 max-w-300 sm:max-h-400 sm:max-w-400 rounded-full"
                src={require("../public/images/index/sourcing.jpg") as string}
              />
            </picture>
          </div>
          <div className="flex-initial m-5">
            <picture>
              <source
                srcSet={require("../public/images/index/ingredients.webp") as string}
                type="image/webp"
              />
              <source
                srcSet={require("../public/images/index/ingredients.jpg") as string}
                type="image/jpeg"
              />
              <img
                alt="Lasting Smiles Ingredients"
                className="max-h-300 max-w-300 sm:max-h-400 sm:max-w-400 rounded-full"
                src={require("../public/images/index/ingredients.jpg") as string}
              />
            </picture>
          </div>
        </div>
        <div className="text-center">
          <Link
            as={toRelativePageURL("/planet", market as string)}
            href={{
              pathname: "/planet",
              query: { market },
            }}
          >
            <a className="bg-black border border-black duration-500 ease-in-out font-medium font-serif hover:bg-default hover:text-black inline-block my-5 px-5 py-3 rounded shadow uppercase text-lg text-white transition">
              Explore Our Ingredients &amp;&nbsp;Materials
            </a>
          </Link>
        </div>
      </div>
    </section>
  );
};

export default Earth;
