/*
 * Copyright (C) 2020-present Borza LLC. All rights reserved.
 */

import Link from "next/link";
import { useRouter } from "next/router";

import { toRelativePageURL } from "../utils/url";

const Nature = () => {
  const {
    query: {
      market,
    },
  } = useRouter();

  return (
    <section className="py-10">
      <div className="max-w-screen-lg mx-auto">
        <div className="flex flex-col items-center sm:flex-row">
          <div className="flex-1 px-2 sm:px-5">
            <h2 className="font-medium font-serif text-2xl text-center">
              Lip care plucked from&nbsp;nature.
            </h2>
            <p className="text-center text-xl">
              Turns out you don't need anything artificial to create an
              indulgently-silky lip hydrator. Just the pure, botanical basics.
              Extravagantly simple.
            </p>
            <div className="text-center">
              <Link
                as={toRelativePageURL("/products", market as string)}
                href={{
                  pathname: "/products",
                  query: { market },
                }}
              >
                <a className="bg-black border border-black duration-500 ease-in-out font-medium font-serif hover:bg-default hover:text-black inline-block my-5 px-5 py-3 rounded shadow uppercase text-lg text-white transition">
                  Shop Lip Balms
                </a>
              </Link>
            </div>
          </div>
          <div className="flex-1 px-2 sm:px-5">
            <picture>
              <source
                srcSet={require("../public/images/photos/blavka-1.webp") as string}
                type="image/webp"
              />
              <source
                srcSet={require("../public/images/photos/blavka-1.jpg") as string}
                type="image/jpeg"
              />
              <img
                alt="Nature"
                className="mx-auto rounded"
                src={require("../public/images/photos/blavka-1.jpg") as string}
              />
            </picture>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Nature;
