/*
 * Copyright (C) 2020-present Borza LLC. All rights reserved.
 */

import Link from "next/link";
import { useRouter } from "next/router";

import { toRelativePageURL } from "../utils/url";

const Purpose = () => {
  const {
    query: {
      market,
    },
  } = useRouter();

  return (
    <>
      <style jsx={true}>
        {`
          .ls-waves > use {
            animation: move-forever 25s cubic-bezier(.55, .5, .45, .5) infinite;
          }

          .ls-waves > use:nth-child(1) {
            animation-delay: -2s;
            animation-duration: 7s;
          }

          .ls-waves > use:nth-child(2) {
            animation-delay: -3s;
            animation-duration: 10s;
          }

          .ls-waves > use:nth-child(3) {
            animation-delay: -4s;
            animation-duration: 13s;
          }

          .ls-waves > use:nth-child(4) {
            animation-delay: -5s;
            animation-duration: 20s;
          }

          @keyframes move-forever {
            0% {
              transform: translate3d(-90px, 0, 0);
            }

            100% {
              transform: translate3d(85px, 0, 0);
            }
          }
        `}
      </style>
      <section className="bg-water pt-10">
        <div className="max-w-screen-lg mx-auto">
          <div className="flex flex-col items-center sm:flex-row">
            <div className="flex-1 px-2 sm:px-5">
              <h2 className="font-medium font-serif text-2xl text-center">
                A ripple of care.
              </h2>
              <p className="text-center text-xl">
                Lasting Smiles is built with a purpose: to care for our lips
                &ndash; while caring for people and our planet.
              </p>
              <div className="text-center">
                <Link
                  as={toRelativePageURL("/purpose", market as string)}
                  href={{
                    pathname: "/purpose",
                    query: { market },
                  }}
                >
                  <a className="bg-black border border-black duration-500 ease-in-out font-medium font-serif hover:bg-default hover:text-black inline-block my-5 px-5 py-3 rounded shadow uppercase text-lg text-white transition">
                    Join Our Mission
                  </a>
                </Link>
              </div>
            </div>
            <div className="flex-1 px-2 sm:px-5">
              <picture>
                <source
                  srcSet={require("../public/images/index/water.webp") as string}
                  type="image/webp"
                />
                <source
                  srcSet={require("../public/images/index/water.jpg") as string}
                  type="image/jpeg"
                />
                <img
                  alt="Water"
                  className="mx-auto rounded"
                  src={require("../public/images/index/water.jpg") as string}
                />
              </picture>
            </div>
          </div>
        </div>
        <svg
          className="block min-h-100 pt-10"
          viewBox="0 24 150 28"
          preserveAspectRatio="none"
          shapeRendering="auto"
        >
          <defs>
            <path
              d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z"
              id="wave"
            />
          </defs>
          <g className="ls-waves">
            <use
              fill="rgba(255, 255, 255, 0.7)"
              xlinkHref="#wave"
              x="48"
              y="0"
            />
            <use
              fill="rgba(255, 255, 255, 0.5)"
              xlinkHref="#wave"
              x="48"
              y="3"
            />
            <use
              fill="rgba(255, 255, 255, 0.3)"
              xlinkHref="#wave"
              x="48"
              y="5"
            />
            <use
              fill="rgb(255, 255, 255)"
              xlinkHref="#wave"
              x="48"
              y="7"
            />
          </g>
        </svg>
      </section>
    </>
  );
};

export default Purpose;
